import React from "react"
import { Typography } from "@material-ui/core"
import { COLORS } from "../../utils/theme"
import Premium from "../../../static/images/premium-feature.svg"
import { useDispatch } from "react-redux"
import { showSubscriptionModal } from "../../redux/slices/subscriptionModalSlice"

export default function SubscriptionChip({
  style,
  notClickable,
  hideImage,
  label,
  textStyle,
}) {
  const dispatch = useDispatch()
  return (
    <div
      onClick={() => !notClickable && dispatch(showSubscriptionModal(true))}
      style={{
        padding: "3px",
        position: "absolute",
        borderWidth: "1px solid transparent",
        borderRadius: "10px",
        backgroundColor: COLORS.lightGreen,
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        right: 14,
        top: -2,
        cursor: notClickable ? "default" : "pointer",
        ...style,
      }}
    >
      {!hideImage && (
        <img
          src={Premium}
          style={{
            height: "16px",
            width: "16px",
            margin: "0px 5px 0px",
          }}
          alt=""
        />
      )}
      <Typography
        style={{
          color: COLORS.offBlack,
          fontWeight: "bold",
          fontSize: "14px",
          marginRight: "4px",
          ...textStyle,
        }}
      >
        {label || "Racqy Plus"}
      </Typography>
    </div>
  )
}
